import { MarketplaceResourcesListData } from 'waldur-js-client';

export const TABLE_PUBLIC_RESOURCE = 'PublicResourcesList';
export const PROVIDER_RESOURCES_LIST_FILTER_FORM_ID = 'ProviderResourcesFilter';
export const PROJECT_RESOURCES_ALL_FILTER_FORM_ID = 'ProjectResourcesAllFilter';
export const CATEGORY_RESOURCES_ALL_FILTER_FORM_ID = 'AllResourcesFilter';

export const ALL_RESOURCES_TABLE_ID = 'AllResourcesList';
/** Along with a Category UUID */
export const CATEGORY_RESOURCES_TABLE_ID = 'UserResourcesList';
export const NON_TERMINATED_STATES: MarketplaceResourcesListData['query']['state'] =
  ['Creating', 'OK', 'Erred', 'Updating', 'Terminating'];
